import logger from 'OK/logger';
import { isElementPartiallyInViewport } from 'OK/utils/utils';
import throttle from 'OK/utils/throttle';
import { getLogDataAsString, parseLogData } from 'OK/LogClicks';
import { LogRender } from 'OK/LogRender';
import { EVENT_TRIGGERED, EVENT_TYPE } from 'OK/LogListenerEventBuses';

var SUCCESS = 'seen',
    ERROR = 'log.seen';

var elements = [];
var instances = [];
var logAtStartTimeout;

var scrollHandler = throttle(150, function logSeenExperimentalChecker() {
    clearLogAtStartTimeout();
    var count = instances.length;
    while (count--) {
        instances[count].onScroll();
    }
});
var unloadHandler = function() {
    var count = instances.length;
    while (count--) {
        instances[count].onUnload();
    }
};

var visibilityChangeHandler = function() {
    clearLogAtStartTimeout();
    var count = instances.length;
    while (count--) {
        instances[count].onVisibilityChange();
    }
};

/**
 * Log operation and parameter to console and server log.
 * @param {string} type
 * @param {string} data
 * @param {boolean=} force
 */
function logSuccess(type, data, force) {
    OK.logging.info(SUCCESS + ' ' + type + ' ' + data);
    logger[force ? 'force' : 'success'](SUCCESS, type, data);

    EVENT_TRIGGERED.emit({
        type: EVENT_TYPE.SEEN,
        payload: data,
    }, null);
}

/**
 * Log error to console and server log.
 * @param {string} message
 */
function logError(message) {
    OK.logging.alert(ERROR + ' ' + message);
    logger.error(ERROR, message);
}

/**
 * Сбрасывает таймаут для логирования просмотра при активации.
 */
function clearLogAtStartTimeout() {
    if (logAtStartTimeout) {
        clearTimeout(logAtStartTimeout);
        logAtStartTimeout = null;
    }
}

class LogSeen {
    constructor() {
        /** @type {{}} */
        this.params = {};
        /** @type {number} */
        this.seenStartTime = 0;
        /** @type {number} */
        this.hoverStartTime = 0;
        /** @type {number} */
        this.onScrollSeenTimer = null;
        /** @type {{}} */
        this.logData = {};
    }

    /**
     *
     * @param {!HTMLElement} element
     * @returns {LogSeen}
     */
    activate(element) {
        this.element = element;
        this.params = JSON.parse(element.getAttribute('data-seen-params'));

        var show = element.getAttribute('data-show') === '1';
        if (show) {
            var logRender = new LogRender();
            logRender.activate(this.element);
        }

        if (this.params.options) {
            this.seenTimeout = this.params.options.seenTimeout; // Как долго элемент был во вьюпорте чтобы мы его залогировали
            this.skipVisible = this.params.options.skipVisible || false; // Нужно ли НЕ активировать все на экране сразу
            this.hoverTimeout = this.params.options.hoverTimeout; // Как долго на элементе был курсор мыши чтобы мы его залогировали
            this.seenPartial = this.params.options.partial || 0.01; // Какая часть элемента попала во вьюпорт
            this.seenForce = this.params.options.force || false; // Сразу ли отправлять статистику или с задержкой

            // Можно ли считать элемент увиденным при скролле если он еще во вьюпорте
            this.onScrollSeen = this.params.options.onScrollSeen || false;
            // Как долго элемент был во вьюпорте чтобы мы его залогировали при скролле
            this.onScrollSeenTimeout = this.params.options.onScrollSeenTimeout || 10000;
            this.disableOnScrollSeenTimeout = this.params.options.disableOnScrollSeenTimeout || false;
            // Какая часть элемента должа попадать во вьюпорт чтобы элемент считать увиденным при скролле
            this.onScrollPartial = this.params.options.onScrollPartial || 0.6;
            // Не логировать элементы с высотой 0 (например, скрытые)
            this.disableNullHeight = this.params.options.disableNullHeight || false;
            this.logAtStart = this.params.options.logAtStart || false;
            this.logBeforeUnload = this.params.options.logBeforeUnload || false;
            this.logVisibilityTabChange = this.params.options.logVisibilityTabChange || false;

            var logAttrVal = getLogDataAsString(element);
            var logParams = logAttrVal && parseLogData(logAttrVal);

            if (logParams && logParams.feedFeatures) {
                this.params.data = this.params.data || {};
                this.params.data.feedFeatures = logParams.feedFeatures;
                if (logParams.feedPosition) {
                    this.params.data.feedPosition = Number(logParams.feedPosition);
                }

                if (logParams.feedLocation) {
                    this.params.data.feedLocation = logParams.feedLocation;
                }
            }
        }

        this.boundMouseEnter = this.mouseEnter.bind(this);
        this.boundMouseLeave = this.mouseLeave.bind(this);
        this.boundClick = this.onClick.bind(this);

        if (this.hoverTimeout) {
            // Устанавливаем обработчики мыши
            this.element.addEventListener('mouseenter', this.boundMouseEnter);
            this.element.addEventListener('mouseleave', this.boundMouseLeave);
        }

        if (this.seenTimeout) {
            this.element.addEventListener('click', this.boundClick);
        }

        if (this.seenTimeout && !this.skipVisible) {
            // Активируем видимые элементы сразу
            var isVisible = isElementPartiallyInViewport(element, this.seenPartial, this.disableNullHeight);
            if (isVisible !== false) {
                // Здесь те, что во вьюпорте или те, что с нулевой высотой и которым это не запрещено
                this.shown(this.getNullHeightLogParam(isVisible));
                if (!logAtStartTimeout && this.logAtStart) {
                    logAtStartTimeout = setTimeout(scrollHandler, this.seenTimeout);
                }
            }
        }

        return this;
    }

    deactivate() {
        if (this.hoverTimeout) {
            this.element.removeEventListener('mouseenter', this.boundMouseEnter);
            this.element.removeEventListener('mouseleave', this.boundMouseLeave);
        }

        if (this.seenTimeout) {
            this.element.removeEventListener('click', this.boundClick);
            this.hidden();
        }
    }

    // Вызывается при показе элемента
    shown(params) {
        this.logData = Object.assign(params, this.params.data);
        this.seenStartTime = Date.now();
    }

    // Вызывается при скролле показанного элемента
    shownOnScroll() {
        if (!this.onScrollSeen || this.onScrollSeenTimer || this.logData.seen) {
            return;
        }

        var isVisibleEnough = isElementPartiallyInViewport(this.element, this.onScrollPartial, this.disableNullHeight);
        if (isVisibleEnough === false) {
            // Явным образом элемент вне вьюпорта
            return;
        }

        // Здесь те, что во вьюпорте или те, что с нулевой высотой, и которым это можно
        this.onScrollSeenTimer = setTimeout(function () {
            if (this.logData.seen) {
                return;
            }
            this.logData.seen = this.onScrollSeenTimeout;
            // Логируем, что мы учли фид с нулевой высотой — переопределяем здесь значение, если оно было задано на активации
            this.logData.nullHeight = (isVisibleEnough === null);
            logSuccess(this.params.type, JSON.stringify(this.logData), this.seenForce);
        }.bind(this), this.onScrollSeenTimeout);
    }

    // Вызывается при скрытии элемента или при его деактивации
    hidden(force) {
        if (this.seenStartTime && !this.logData.seen) {
            var seenTime = Date.now() - this.seenStartTime;
            if (seenTime >= this.seenTimeout) {
                this.logData.seen = seenTime;
                logSuccess(this.params.type, JSON.stringify(this.logData), force || this.seenForce);
            }
        }
        this.logData.seen = 0;
        this.seenStartTime = 0;
        if (this.onScrollSeenTimer) {
            clearTimeout(this.onScrollSeenTimer);
            this.onScrollSeenTimer = null;
        }
    }

    // Вызывается при наведении мыши на элемент
    mouseEnter() {
        this.hoverStartTime = Date.now();
    }

    // Вызывается при убирании мыши с элемента
    mouseLeave() {
        if (this.hoverStartTime) {
            var hoverTime = Date.now() - this.hoverStartTime;
            if (hoverTime >= this.hoverTimeout) {
                this.logData.hovered = hoverTime;
            }
        }
        this.hoverStartTime = 0;
    }

    onUnload() {
        if (this.seenStartTime && this.logBeforeUnload) {
            this.hidden(true);
        }
    }

    onVisibilityChange() {
        if (!this.logVisibilityTabChange || !this.seenTimeout) {
            return;
        }

        if (this.seenStartTime && document.visibilityState === 'hidden') {
            this.hidden();
            return;
        }

        const isVisible = isElementPartiallyInViewport(this.element, this.seenPartial, this.disableNullHeight);
        if (!this.seenStartTime &&
            document.visibilityState === 'visible' &&
            isVisible !== false) {
            this.shown(this.getNullHeightLogParam(isVisible));
        }
    }

    onScroll() {
        if (this.seenTimeout) {
            var isVisible = isElementPartiallyInViewport(this.element, this.seenPartial, this.disableNullHeight);
            if (isVisible !== false) {
                // Здесь те, что видимы и те, которым можно быть с нулевой высотой
                if (!this.seenStartTime) {
                    this.shown(this.getNullHeightLogParam(isVisible));
                }

                if (!this.disableOnScrollSeenTimeout) {
                    this.shownOnScroll();
                }
            } else {
                // здесь те, что вне вьюпорта и те, которым запрещено быть с нулевой высотой
                if (this.disableNullHeight !== true) {
                    // здесь те, кому не запрещено быть с нулевой высотой
                    this.hidden();
                }
            }
        }
    }

    // Обработчик клика
    onClick() {
        if (this.seenStartTime) {
            this.logData.seen = Date.now() - this.seenStartTime;
        } else {
            if (this.skipVisible) {
                // если клик есть, то пользователь все-таки увидел фид
                this.shown(this.getNullHeightLogParam(true));
            }
            this.logData.seen = 0;
        }
        this.logData.seen = this.seenStartTime
            ? Date.now() - this.seenStartTime
            : 0;
        this.logData.clicked = true;
        logSuccess(this.params.type, JSON.stringify(this.logData), this.seenForce);
        this.seenStartTime = 0;
    }

    /**
     * Формирует начальные данные о видимости фида
     * @param {boolean|Null} isVisible
     */
    getNullHeightLogParam(isVisible) {
        return {
            nullHeight: (isVisible === null)
        };
    }
}


/**
 *
 * @param {!HTMLElement} element
 */
function activate(element) {
    var index = elements.indexOf(element);
    if (index === -1) {
        elements.push(element);
        instances.push(new LogSeen().activate(element));
    }
    if (instances.length === 1) {
        window.addEventListener('scroll', scrollHandler, true);
        window.addEventListener('beforeunload', unloadHandler);

        if (instances[0].logVisibilityTabChange) {
            document.addEventListener('visibilitychange', visibilityChangeHandler);
        }
    }
}

/**
 *
 * @param {!HTMLElement} element
 * @returns {boolean}
 */
function deactivate(element) {
    var index = elements.indexOf(element);
    if (index !== -1) {
        instances[index].deactivate();
        elements.splice(index, 1);
        instances.splice(index, 1);
    }
    clearLogAtStartTimeout();
    if (!instances.length) {
        window.removeEventListener('scroll', scrollHandler, true);
        window.removeEventListener('beforeunload', unloadHandler);
        document.removeEventListener('visibilitychange', visibilityChangeHandler);
    }
}

export default { logError, logSuccess, activate, deactivate };

export { logError, logSuccess, activate, deactivate };
